export default {
  buildMenuFromRoutes(routes, user_type) {
    // menu dictionary
    const menu = {};
    // construct menu items
    let top_level_id = 1;
    // iterate through BHealthy application routes only routes that have a  meta propoerty and a folder specified and either no user_type or user_type matches logged in user
    for (let item of routes.children.filter(o => o.meta && (o.meta.folder && (!o.meta.user_type || user_type === o.meta.user_type || o.meta.user_type === 'both')))) {
      const folder_key = item.meta.folder
        .toLowerCase()
        .replace(' ', '_')
        .replace('-', '_');
      // do a dictionary lookup
      if (!menu[folder_key]) {
        // key doesn't exist so create a new top level menu
        menu[folder_key] = {
          id: `${top_level_id}`,
          label: item.meta.folder,
          subRoutes: [],
        };
        top_level_id += 1;
      }

      // insert menu item under menu
      const folder = menu[folder_key];
      const num_items = folder.subRoutes.length;
      var menu_item = {
        id: `${folder.id}-${num_items + 1}`,
        text: item.meta.text,
        route: item.name,
      };
      folder.subRoutes.push(menu_item);
    }

    // build menu from dictionary
    let retval = Object.values(menu);
    // sorting everything by their ids
    retval.sort(this.sortIds);
    for (let menu of retval) {
      menu.subRoutes.sort(this.sortIds);
    }

    return retval;
  },
}